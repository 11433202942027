class ZIDXAccountSiteChangePlan implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSiteChangePlanContainer";
    }
    getMatchURL(){
        return "/account/sites/plan/edit";
    }
    render(){
        // console.log("site change plan");
    }
}